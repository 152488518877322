window._fitAnalyticsReportPurchase = function () {
  const shopPrefix = 'tnf_eu';

  const shop = (window._fitAnalyticsReportPurchase!.shop = {
    timers: {
      onPageLoaded: setInterval(() => {
        // @ts-ignore
        const purchase: OCPData = window.fitFinder ?? {};

        if (!purchase.products || !purchase.products?.length) {
          return;
        } else {
          clearInterval(shop.timers.onPageLoaded);
        }

        shop.sendPurchase();
      }, 50)
    },
    sendPurchase() {
      // @ts-ignore
      const purchase: OCPData = window.fitFinder;

      for (const product of purchase.products ?? []) {
        const response: PurchaseInformation = {
          // @ts-ignore
          userId: purchase?.userId ?? '',
          orderId: purchase?.orderId ?? '',
          currency: purchase?.currency ?? '',
          language: purchase?.shopLanguage ?? '',
          shopCountry: purchase?.shopCountry ?? '',
          price: product?.price ?? '',
          quantity: product?.quantity ?? '',
          purchasedSize: product?.size ?? '',
          shopArticleCode: product?.itemId ?? '',
          shopSessionId: purchase?.shopSessionId ?? '',
          productSerial: product?.itemSubgroupId ? `${shopPrefix}-${product?.itemSubgroupId}` : '',
          shopPrefix
        };

        window._sendPurchaseInformation(response);
      }
    }
  });
};

(() => {
  const $script = document.createElement('script');
  $script.setAttribute('src', '//widget.fitanalytics.com/purchase_reporter.js');
  $script.setAttribute('type', 'text/javascript');
  $script.setAttribute('async', 'true');
  document.body.appendChild($script);
})();
